import $ from "jquery";

import Scrollbar from "smooth-scrollbar";
import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

window.addEventListener("DOMContentLoaded", (e) => {
	console.log("DOMContentLoaded");

	console.log("Loaded");
	gsap.registerPlugin(ScrollTrigger);
	let bodyScrollBar;
	let scroller;
	const select = (e) => document.querySelector(e);
	const body = document.querySelector("body");

	if (window.innerWidth <= 760) {
		let $h2Mm = gsap.timeline();
		$h2Mm
			.to(".mn_screen .intro_hold .intro_img07", 1.8, {
				scale: 1,
				clipPath: "inset(0%)",
				ease: "expo.out",
			})
			.add("a")
			.to(
				".mn_screen .intro_hold .intro_img02",
				1.8,
				{
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				},
				`-=${0.85 * 1.8}`
			) //.to('.mn_screen .intro_hold .intro_img05', 1.8, { scale: 1, clipPath: 'inset(0%)', ease: "expo.out" }, `-=${0.85 * 1.8}`)
			.to(
				".mn_screen .intro_hold .intro_img04",
				1.8,
				{
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				},
				`-=${0.85 * 1.8}`
			)
			.to(
				".mn_screen .intro_hold .intro_img03",
				1.8,
				{
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				},
				`-=${0.85 * 1.8}`
			)
			.to(
				".mn_screen .intro_hold .intro_img01",
				1.8,
				{
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				},
				`-=${0.85 * 1.8}`
			)
			.to(
				".mn_screen .intro_hold .intro_img06",
				1.8,
				{
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				},
				`-=${0.85 * 1.8}`
			)
			.to(
				".intro_hold_h1 .h2g",
				1.8,
				{
					y: 0,
					ease: "expo.out",
				},
				`-=1.9`
			)
			.to(
				".ms_txt",
				1.5,
				{
					autoAlpha: 1,
					y: 0,
					ease: "expo.out",
				},
				"a+=1"
			)
			.to(
				".mn_header",
				1,
				{
					autoAlpha: 1,
					pointerEvents: "auto",
					y: 0,
					ease: "expo.out",
					immediateRender: false,
				},
				"a+=1"
			)
			.to(
				".h_burger_mb",
				1,
				{
					autoAlpha: 1,
					pointerEvents: "auto",
					y: 0,
					ease: "expo.out",
					immediateRender: false,
				},
				"a+=1"
			)
			.to(".hdn", 0.01, {
				height: "auto",
				onComplete: initPageMb,
			});
	} else if (window.innerWidth > 760) {
		let $h2M = gsap.timeline();
		$h2M.to(".mn_screen .intro_hold .intro_img01", 1.8, {
			scale: 1,
			clipPath: "inset(0%)",
			ease: "expo.out",
		})
			.add("a")
			.to(
				".mn_screen .intro_hold .intro_img02",
				1.8,
				{
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				},
				`-=${0.85 * 1.8}`
			)
			.to(
				".mn_screen .intro_hold .intro_img05",
				1.8,
				{
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				},
				`-=${0.85 * 1.8}`
			)
			.to(
				".mn_screen .intro_hold .intro_img04",
				1.8,
				{
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				},
				`-=${0.85 * 1.8}`
			)
			.to(
				".mn_screen .intro_hold .intro_img03",
				1.8,
				{
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				},
				`-=${0.85 * 1.8}`
			)

			.to(
				".mn_screen .intro_hold .intro_img06",
				1.8,
				{
					scale: 1,
					clipPath: "inset(0%)",
					ease: "expo.out",
				},
				`-=${0.85 * 1.8}`
			)
			.to(
				".intro_hold_h1 .h2g",
				1.8,
				{
					y: 0,
					ease: "expo.out",
				},
				`-=1.9`
			)

			.to(
				".mn_header",
				1,
				{
					autoAlpha: 1,
					pointerEvents: "auto",
					y: 0,
					ease: "expo.out",
				},
				"a+=.7"
			)
			.to(
				".hdn",
				0.01,
				{
					height: "auto",
					onComplete: initPage,
				},
				"a+=.7"
			);
	}

	function initPage() {
		console.log("initPage");
		// startScroll();
		// initMatchMedia();
		// initOther();
		// initScrollTo();

		startScroll();
		initMatchMedia();
		initOther();
		initScrollTo();
		animateWords();

		fadeIns();
	}

	function animateWords() {
		const section = document.querySelector('.crafting-living-section');
		const words = gsap.utils.toArray(".crafting-living-section .animateWords span");
	
		const timeline = gsap.timeline({
			scrollTrigger: {
				// markers: true,
				trigger: section,
				start: "top center", // Start animation when the top of the section reaches the bottom of the viewport
				end: "top 100px", // End animation when the bottom of the section reaches the top of the viewport
				scrub: 1,
			}
		});
	
		words.forEach((word, index) => {
			timeline.to(word, { opacity: 1 } );
		});
	}

	function fadeIns() {

		// fadeInUp
		const fadeInUp = gsap.utils.toArray('.fadeInUp');
		fadeInUp.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, y: -200 }, { duration: 0.5, autoAlpha: 1, y: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: 'play none none none',
				once: false,
			});
		});
		// fadeInUpBig
		const fadeInUpBig = gsap.utils.toArray('.fadeInUpBig');
		fadeInUpBig.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, y: -500 }, { duration: 1, autoAlpha: 1, y: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: 'play none none none',
				once: false,
			});
		});
		// fadeInDown
		const fadeInDown = gsap.utils.toArray('.fadeInDown');
		fadeInDown.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, y: 200 }, { duration: 0.5, autoAlpha: 1, y: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: 'play none none none',
				once: false,
			});
		});
		// fadeInDownBig
		const fadeInDownBig = gsap.utils.toArray('.fadeInDownBig');
		fadeInDownBig.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, y: 500 }, { duration: 1, autoAlpha: 1, y: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: 'play none none none',
				once: false,
			});
		});
		// fadeInLeft
		const fadeInLeft = gsap.utils.toArray('.fadeInLeft');
		fadeInLeft.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, x: -200 }, { duration: 0.5, autoAlpha: 1, x: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: 'play none none none',
				once: false,
			});
		});
		// fadeInLeftBig
		const fadeInLeftBig = gsap.utils.toArray('.fadeInLeftBig');
		fadeInLeftBig.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, x: -500 }, { duration: 1, autoAlpha: 1, x: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: 'play none none none',
				once: false,
			});
		});

		// fadeInRight
		const fadeInRight = gsap.utils.toArray('.fadeInRight');
		fadeInRight.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, x: 200 }, { duration: 0.5, autoAlpha: 1, x: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: 'play none none none',
				once: false,
			});
		});

		// fadeInRightBig
		const fadeInRightBig = gsap.utils.toArray('.fadeInRightBig');
		fadeInRightBig.forEach((box, i) => {
			const anim = gsap.fromTo(box, { autoAlpha: 0, x: 500 }, { duration: 1, autoAlpha: 1, x: 0 });
			ScrollTrigger.create({
				trigger: box,
				animation: anim,
				toggleActions: 'play none none none',
				once: false,
			});
		});
	}	

	function initPageMb() {
		$("body").removeClass("hdn");
		initMatchMedia();
		initOther();
	}

	function startScroll() {
		console.log("startScroll");
		function initScroll() {
			console.log("initScroll");
			scroller = document.querySelector(".scroller");

			console.log("got scroller", scroller);

			// 3rd party library setup:
			bodyScrollBar = Scrollbar.init(scroller, {
				damping: 0.07,
				delegateTo: document,
				renderByPixels: true,
				keyDownDisabled: false,
				alwaysShowTracks: true,
			});

			// Tell ScrollTrigger to use these proxy getter/setter methods for the "body" element:
			ScrollTrigger.scrollerProxy(".scroller", {
				scrollTop(value) {
					if (arguments.length) {
						bodyScrollBar.scrollTop = value;
					}
					return bodyScrollBar.scrollTop;
				},
			});

			// when the smooth scroller updates, tell ScrollTrigger to update() too:
			bodyScrollBar.addListener(ScrollTrigger.update);

			ScrollTrigger.defaults({
				scroller: scroller,
			});

			// remove x scrollbar
			bodyScrollBar.track.xAxis.element.remove();
		}

		if (window.innerWidth > 760) {
			initScroll();
			// customCursor();
		} else if (window.innerWidth <= 760) {
			Scrollbar.destroyAll();
		}
	}

	function customCursor() {
		const cursor = document.querySelector("#c");

		const mouse = {
			x: 0,
			y: 0,
		};
		// mouse pointer's coordinates
		const pos = {
			x: 0,
			y: 0,
		};
		// cursor's coordinates
		const speed = 0.1;
		// between 0 and 1

		const updateCoordinates = (e) => {
			mouse.x = e.clientX;
			mouse.y = e.clientY;
		};

		window.addEventListener("mousemove", updateCoordinates);

		let lastUpdateTime = 0;

		const updateCursor = (timestamp) => {
			if (timestamp - lastUpdateTime > 16) {
				// Update every 16ms (about 60 FPS)
				lastUpdateTime = timestamp;
				if (bodyScrollBar && bodyScrollBar.offset) {
					let sci = bodyScrollBar.offset.y;
					const diffX = Math.round(mouse.x - pos.x);
					const diffY = Math.round(mouse.y - pos.y + sci);
					pos.x += diffX * speed;
					pos.y += diffY * speed;
					const translate = "translate3d(" + pos.x + "px ," + pos.y + "px, 0)";
					cursor.style.transform = translate;
				} else {
					// Если bodyScrollBar или его свойство offset не существуют, выполняются здесь какие-либо альтернативные действия.
				}
			}
			requestAnimationFrame(updateCursor);
		};

		requestAnimationFrame(updateCursor);

		const cursorModifiers = document.querySelectorAll("[data-cursor-class]");

		cursorModifiers.forEach((curosrModifier) => {
			curosrModifier.addEventListener("mouseenter", function () {
				const className = this.getAttribute("data-cursor-class");
				cursor.classList.add(className);
			});

			curosrModifier.addEventListener("mouseleave", function () {
				const className = this.getAttribute("data-cursor-class");
				cursor.classList.remove(className);
			});
		});
	}
	function initScrollTo() {
		// find all links and animate to the right position
		gsap.utils.toArray(".linkTo").forEach((link) => {
			const target = link.getAttribute("href");
			link.addEventListener("click", (e) => {
				e.preventDefault();
				//console.log(select(target));
				bodyScrollBar.scrollIntoView(select(target), {
					damping: 0.07,
					offsetTop: 0,
				});
			});
		});
	}
	function initOther() {
		console.log('initOther');

		/*new WOW().init();*/
		console.log('init');

		const activateButtonMenu = document.querySelector(".h_burger .hb_btn");
		const activateButtonMenuMb = document.querySelector(".h_burger_mb .hb_btn");
		const deactivateButtonmenu = document.querySelector(".v_menu_cls");
		const vMenu = document.querySelector(".v_menu");
		const sapBtnVm = document.querySelector(".sap_btn_vm");
		const sapForm = document.querySelector(".sap_form");

		function deactivateSmoothScrollbar() {
			// if (bodyScrollBar) {
			// 	bodyScrollBar.destroy();
			// 	bodyScrollBar = null;
			// }

			// body.classList.add('hd');
			vMenu.classList.add("active");
		}
		function activateSmoothScrollbar() {
			// const scroller = document.querySelector('.scroller');
			// bodyScrollBar = Scrollbar.init(scroller, {
			// 	damping: 0.07,
			// 	delegateTo: document,
			// 	renderByPixels: true,
			// 	keyDownDisabled: false,
			// 	alwaysShowTracks: true,
			// });
			// ScrollTrigger.scrollerProxy(".scroller", {
			// 	scrollTop(value) {
			// 		if (arguments.length) {
			// 			bodyScrollBar.scrollTop = value;
			// 		}
			// 		return bodyScrollBar.scrollTop;
			// 	}
			// });
			// bodyScrollBar.addListener(ScrollTrigger.update);
			// ScrollTrigger.defaults({
			// 	scroller: scroller
			// });

			vMenu.classList.remove("active");
			//body.classList.remove('hd');
		}
		// TODO: reactivate
		// activateButtonMenu.addEventListener("click", deactivateSmoothScrollbar);
		// activateButtonMenuMb.addEventListener("click", deactivateSmoothScrollbar);
		// deactivateButtonmenu.addEventListener("click", activateSmoothScrollbar);

		// sapBtnVm.addEventListener("click", function () {
		// 	body.classList.remove("hd");
		// 	sapForm.classList.add("active");
		// 	setTimeout(() => {
		// 		vMenu.classList.add("ops");
		// 	}, 350);

		// 	setTimeout(() => {
		// 		vMenu.classList.remove("active");
		// 		vMenu.classList.remove("ops");
		// 	}, 700);
		// });

		if ($(".apartment_premises").length) {
			// Получаем элементы, с которыми будем работать
			const img01 = document.querySelector(".apartment_premises .rows .cols01 .d_img");
			const img02 = document.querySelector(".apartment_premises .rows .cols02 .d_img");

			// Добавляем обработчики событий для наведения и ухода мыши
			img01.addEventListener("mouseenter", () => {
				img01.classList.remove("active");
				img02.classList.add("active");
			});

			img02.addEventListener("mouseenter", () => {
				img02.classList.remove("active");
				img01.classList.add("active");
			});
		}

		// if($('.big_txt').length){
		// 	initScrollLetters();
		// }
		if ($(".big_txt").length) {
			// Scrolling Letters Both Direction
			// https://codepen.io/GreenSock/pen/rNjvgjo
			// Fixed example with resizing
			// https://codepen.io/GreenSock/pen/QWqoKBv?editors=0010

			let direction = 1;
			// 1 = forward, -1 = backward scroll

			const roll1 = roll(".big_txt .txt_wrap", {
					duration: 36,
				}),
				roll2 = roll(
					".rollingText02",
					{
						duration: 10,
					},
					true
				),
				scroll = ScrollTrigger.create({
					trigger: document.querySelector("[data-scroll-container]"),
					onUpdate(self) {
						if (self.direction !== direction) {
							direction *= -1;
							gsap.to([roll1, roll2], {
								timeScale: direction,
								overwrite: true,
							});
						}
					},
				});

			// helper function that clones the targets, places them next to the original, then animates the xPercent in a loop to make it appear to roll across the screen in a seamless loop.
			function roll(targets, vars, reverse) {
				vars = vars || {};
				vars.ease || (vars.ease = "none");
				const tl = gsap.timeline({
						repeat: -1,
						onReverseComplete() {
							this.totalTime(this.rawTime() + this.duration() * 10);
							// otherwise when the playhead gets back to the beginning, it'd stop. So push the playhead forward 10 iterations (it could be any number)
						},
					}),
					elements = gsap.utils.toArray(targets),
					clones = elements.map((el) => {
						let clone = el.cloneNode(true);
						el.parentNode.appendChild(clone);
						return clone;
					}),
					positionClones = () =>
						elements.forEach((el, i) =>
							gsap.set(clones[i], {
								position: "absolute",
								overwrite: false,
								top: el.offsetTop,
								left: el.offsetLeft + (reverse ? -el.offsetWidth : el.offsetWidth),
							})
						);
				positionClones();
				elements.forEach((el, i) =>
					tl.to(
						[el, clones[i]],
						{
							xPercent: reverse ? 100 : -100,
							...vars,
						},
						0
					)
				);
				window.addEventListener("resize", () => {
					let time = tl.totalTime();
					// record the current time
					tl.totalTime(0);
					// rewind and clear out the timeline
					positionClones();
					// reposition
					tl.totalTime(time);
					// jump back to the proper time
				});
				return tl;
			}
		}

		// $('.sap_btn').click(function(e){
		// 	e.preventDefault();
		// 	if (bodyScrollBar) {
		// 		bodyScrollBar.destroy();
		// 		bodyScrollBar = null;
		// 	}
		// 	body.classList.add('hd');
		// 	$(this).toggleClass('active')
		// 	$('.sap_form').toggleClass('active');

		// });

		$(".sap_btn").click(function (e) {
			e.preventDefault();
			vMenu.classList.remove("active");
			body.classList.remove("hd");
			if (bodyScrollBar) {
				// Заблокировать скролл
				bodyScrollBar.setPosition(bodyScrollBar.offset.x, bodyScrollBar.offset.y);

				// Выполнить логику
				body.classList.add("hd");
				$(this).toggleClass("active");
				$(".sap_form").addClass("active");

				// Разблокировать скролл через короткую задержку
				setTimeout(() => {
					bodyScrollBar.setPosition(bodyScrollBar.offset.x, bodyScrollBar.offset.y);
				}, 1000);
			}

			if (window.innerWidth <= 760) {
				body.classList.add("hd");
				$(this).toggleClass("active");
				$(".sap_form").addClass("active");
			}
		});

		$(".sap_form_cls").click(function (e) {
			e.preventDefault();
			if (window.innerWidth > 760) {
				startScroll();
			}
			$(".sap_form").removeClass("active");
		});
	}

	function initMatchMedia() {
		console.log("initMatchMedia");

		ScrollTrigger.matchMedia({
			"(min-width: 320px) and (max-width: 760px)": function () {
				const h = window.innerHeight;
				const w = window.innerWidth;

				const hBurgermb = document.querySelector(".h_burger_mb");
				ScrollTrigger.create({
					id: "h_burger_mb",
					trigger: ".h_burger_mb",
					start: `top top+=${hBurgermb}`,
					end: "max",
					//end: `+=2000`,
					pin: true,
					pinSpacing: false,
					scrub: true,
					//toggleClass: "active",
					//markers: true
				});

				ScrollTrigger.create({
					id: "v_menu_wrap",
					trigger: ".v_menu_wrap",
					start: "top top",
					//end: "top top-=10000",
					end: "max",
					pin: true,
					pinSpacing: false,
					scrub: true,
				});

				if ($(".mn_screen").length) {
					let tlFixFirstScreen = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreen",
							trigger: ".mn_screen",
							pin: true,
							pinSpacing: true,
							scrub: 1,
							start: `top top`,
							end: `+=${h * 1.25}`,
							//immediateRender: true,
						},
					});

					let tlFixFirstScreenMove01 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreen",
							trigger: "#mns_id_set1",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
							// markers: true,
						},
					});
					tlFixFirstScreenMove01
						.add("f")
						.to(
							".intro_img01",
							1,
							{
								xPercent: -250,
								yPercent: 220,
								ease: "none",
							},
							"f"
						)
						.to(
							".ms_txt",
							0.5,
							{
								autoAlpha: 0,
								ease: "none",
							},
							"f"
						)
						.to(
							".mdl_cnt",
							0.1,
							{
								autoAlpha: 1,
								ease: "none",
							},
							"f"
						)
						.to(
							".we_guarantee_quality",
							0.1,
							{
								autoAlpha: 1,
								ease: "none",
							},
							"f"
						);

					let tlFixFirstScreenMove01Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove01Ops",
							trigger: "#mns_id_set1_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
							//markers: true
						},
					});
					tlFixFirstScreenMove01Ops.to(".intro_img01", {
						autoAlpha: 0,
						ease: "none",
					});

					let tlFixFirstScreenMove02 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove02",
							trigger: "#mns_id_set2",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
							//markers: true
						},
					});
					tlFixFirstScreenMove02.to(".intro_img02", {
						xPercent: -180,
						yPercent: -250,
						ease: "none",
					});

					let tlFixFirstScreenMove02Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove02Ops",
							trigger: "#mns_id_set2_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
							//markers: true
						},
					});
					tlFixFirstScreenMove02Ops.to(".intro_img02", {
						autoAlpha: 0,
						ease: "none",
					});

					let tlFixFirstScreenMove06 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove06",
							trigger: "#mns_id_set6",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove06.to(".intro_img06", {
						xPercent: 195,
						yPercent: 235,
						ease: "none",
					});

					let tlFixFirstScreenMove06Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove06Ops",
							trigger: "#mns_id_set6_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove06Ops.to(".intro_img06", {
						autoAlpha: 0,
						ease: "none",
					});

					// let tlFixFirstScreenMove05 = gsap.timeline({
					// 	scrollTrigger: {
					// 		id: "tlFixFirstScreenMove05",
					// 		trigger: '#mns_id_set5',
					// 		pin: false,
					// 		pinSpacing: false,
					// 		scrub: 1,
					// 		start: `top top`,
					// 		end: `+=${h}`,
					// 		//immediateRender: true,
					// 	}
					// });
					// tlFixFirstScreenMove05
					// 	.to('.intro_img05', {xPercent: 165, yPercent: -150, ease: 'none'});

					// let tlFixFirstScreenMove05Ops = gsap.timeline({
					// 	scrollTrigger: {
					// 		id: "tlFixFirstScreenMove05Ops",
					// 		trigger: '#mns_id_set5_ops',
					// 		pin: false,
					// 		pinSpacing: false,
					// 		scrub: 1,
					// 		start: `top top`,
					// 		end: `+=50`,
					// 		//immediateRender: true,
					// 	}
					// });
					// tlFixFirstScreenMove05Ops
					// 	.to('.intro_img05', {autoAlpha: .3,  ease: 'none'});

					let tlFixFirstScreenMove04 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove04",
							trigger: "#mns_id_set4",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove04.to(".intro_img04", {
						xPercent: 50,
						yPercent: 270,
						ease: "none",
					});

					let tlFixFirstScreenMove04Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove04Ops",
							trigger: "#mns_id_set4_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove04Ops.to(".intro_img04", {
						autoAlpha: 0,
						ease: "none",
					});

					let tlFixFirstScreenMove07 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove07",
							trigger: "#mns_id_set7",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove07.to(".intro_img07", {
						xPercent: 135,
						yPercent: -260,
						ease: "none",
					});

					let tlFixFirstScreenMove07Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove07Ops",
							trigger: "#mns_id_set7_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove07Ops.to(".intro_img07", {
						autoAlpha: 0,
						ease: "none",
					});

					let tlFixFirstScreenMove03 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove03",
							trigger: "#mns_id_set3",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove03.to(".intro_img03", {
						xPercent: -120,
						yPercent: -180,
						ease: "none",
					});

					let tlFixFirstScreenMove03Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove03Ops",
							trigger: "#mns_id_set3_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove03Ops.to(".intro_img03", {
						autoAlpha: 0,
						ease: "none",
					});
					// let tlFixFirstScreenOpc = gsap.timeline({
					// 	scrollTrigger: {
					// 		id: "tlFixFirstScreenOpc",
					// 		trigger: '#mns_id_set2_ops',
					// 		pin: false,
					// 		pinSpacing: false,
					// 		scrub: 1,
					// 		start: `top top`,
					// 		end: `+=50`,
					// 		//immediateRender: true,
					// 	}
					// });
					// tlFixFirstScreenOpc
					// 	.add('fo')
					// 	.to('.intro_img01', {autoAlpha: .3,  ease: 'none'}, 'fo')
					// 	.to('.intro_img02', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img03', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img04', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img05', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img06', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img07', {autoAlpha: .3, ease: 'none'}, 'fo');
				}

				ScrollTrigger.create({
					id: "sfo",
					trigger: ".sap_form_ovr",
					start: "top top",
					//end: "top top-=10000",
					end: "max",
					pin: true,
					pinSpacing: false,
					scrub: true,
				});

				if ($(".mdl_cnt").length) {
					let tlMdlLnH = gsap.timeline({
						scrollTrigger: {
							id: "tlMdlLnH",
							trigger: ".mdl_cnt .rows",
							pin: false,
							pinSpacing: false,
							scrub: false,
							start: `bottom bottom-=150`,
							//end: `+=${h * 2}`,
							//immediateRender: true,
						},
					});
					tlMdlLnH
						.add("s")
						.to(
							".mdl_cnt .rows .mdl_ln_h span",
							1,
							{
								scaleX: 1,
								ease: "none",
							},
							"s"
						)
						.to(
							".mdl_cnt .rows .mdl_ln_v span",
							1,
							{
								scaleY: 1,
								ease: "none",
							},
							"s"
						);
				}

				if ($(".we_guarantee_quality").length) {
					let tlWGQTTL = gsap.timeline({
						scrollTrigger: {
							id: "tlWGQTTL",
							trigger: ".we_guarantee_quality .ttl",
							pin: false,
							pinSpacing: false,
							scrub: false,
							start: `bottom bottom+=250`,
							//end: `+=${h * 2}`,
							//immediateRender: true,
						},
					});
					tlWGQTTL.to(".we_guarantee_quality .ttl .h3", 1.8, {
						scale: 1,
						y: 0,
						ease: "power2.inOut",
					});
				}

				if ($(".apartment_premises").length) {
					let tlAPRMB = gsap.timeline({
						scrollTrigger: {
							id: "tlAPRMB",
							trigger: ".apartment_premises .rows_mb .cols01",
							pin: true,
							pinSpacing: false,
							scrub: true,
							start: `top top`,
							//end: `+=${h * 2}`,
							//immediateRender: true,
						},
					});
					let tlAPRMBSCL = gsap.timeline({
						scrollTrigger: {
							id: "tlAPRMBSCL",
							trigger: "#rows_mb_scl01",
							pin: false,
							pinSpacing: false,
							scrub: true,
							start: `top top`,
							end: `+=${h / 2}`,
							//immediateRender: true,
						},
					});
					tlAPRMBSCL.to(".apartment_premises .rows_mb .cols01", {
						scale: 0.88,
						y: 0,
						ease: "power2.inOut",
					});
				}

				if ($(".completed_projects").length) {
					let tlEighty = gsap.timeline({
						scrollTrigger: {
							id: "tlEighty",
							trigger: ".eighty_wrap",
							pin: true,
							pinSpacing: true,
							scrub: true,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});

					let tlEighty1 = gsap.timeline({
						scrollTrigger: {
							id: "tlEighty1",
							trigger: "#eighty_wrap_tr",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h * 2}`,
							immediateRender: true,
						},
					});
					tlEighty1
						.to(".eighty", {
							scale: 12,
							rotation: 48,
							ease: "none",
						})
						.to(".eighty", {
							scale: 30,
							rotation: 90,
							ease: "none",
						});

					// let tlEighty2 = gsap.timeline({
					// 	scrollTrigger: {
					// 		id: "tlEighty2",
					// 		trigger: '#eighty_wrap_tr',
					// 		pin: false,
					// 		pinSpacing: false,
					// 		scrub: 1,
					// 		start: `top top-=${h}`,
					// 		end: `+=${h}`,
					// 		immediateRender: false,
					// 	}
					// });
					// tlEighty2
					// 	.to('.eighty', {scale: 40, rotation: 90, ease: 'none'});

					let tlEightyTXT = gsap.timeline({
						scrollTrigger: {
							id: "tlEightyTXT",
							trigger: ".eighty_wrap",
							pin: false,
							pinSpacing: false,
							scrub: true,
							start: `top top+=100`,
							end: `+=${h / 2}`,
							immediateRender: true,
						},
					});
					tlEightyTXT
						.add("e")
						.to(
							".eighty .pl",
							{
								autoAlpha: 0,
								ease: "none",
							},
							"e"
						)
						.to(
							".eighty_txt",
							{
								autoAlpha: 0,
								ease: "none",
							},
							"e"
						);
				}

				if ($(".help_you_realize").length) {
					let tlHUR = gsap.timeline({
						scrollTrigger: {
							id: "tlHUR",
							trigger: ".help_you_realize",
							pin: false,
							pinSpacing: false,
							scrub: false,
							start: `top bottom-=50`,
							//end: `+=${h * 2}`,
							//immediateRender: true,
						},
					});
					tlHUR //.add('h')
						.fromTo(
							".help_you_realize .cols01 .h3",
							1,
							{
								y: 45,
								autoAlpha: 0,
							},
							{
								y: 0,
								autoAlpha: 1,
								delay: 0.15,
								duration: 1.25,
								ease: "power3.inOut",
							}
						)
						.to(
							".help_you_realize .hyr_ln_h span",
							1,
							{
								scaleX: 1,
								ease: "none",
							},
							"-=.5"
						)
						.fromTo(
							".help_you_realize .cols03 .h3",
							1,
							{
								y: 45,
								autoAlpha: 0,
							},
							{
								y: 0,
								autoAlpha: 1,
								delay: 0.15,
								duration: 1.25,
								ease: "power3.inOut",
							},
							"-=.5"
						);
				}

				if ($(".b_prlx").length) {
					let tlBPX = gsap.timeline({
						scrollTrigger: {
							id: "tlBPX",
							trigger: ".b_prlx",
							pin: false,
							pinSpacing: false,
							scrub: true,
							start: `center center`,
							end: "bottom top", //end: `+=${h * 2}`,
							//immediateRender: true,
						},
					});
					tlBPX.to(".b_prlx img", 1, {
						y: "10.938vw",
						ease: "none",
					});
					// gsap.utils.toArray('.b_prlx').forEach(section => {
					// 	const image = section.querySelector('img');
					// 	gsap.to(image, {
					// 		yPercent: 0,
					// 		ease: 'none',
					// 		scrollTrigger: {
					// 			trigger: section,
					// 			start: 'top bottom',
					// 			end: 'bottom top',
					// 			scrub: true
					// 		}
					// 	});
					// });
				}

				if ($(".prlx").length) {
					// select all sections .with-parallax
					gsap.utils.toArray(".prlx").forEach((section) => {
						// get the image
						const image = section.querySelector("img");
						// create tween for the image
						gsap.to(image, {
							yPercent: 40,
							ease: "none",
							scrollTrigger: {
								trigger: section,
								start: "top bottom",
								end: "bottom top",
								scrub: true,
							},
						});
					});
				}
				if ($(".doubl_txt_wrap").length) {
					//const mvRowsMove = document.querySelectorAll('.doubl_txt_wrap .rows .dt_ovr');

					gsap.utils.toArray(".doubl_txt_wrap .rows .dt_ovr").forEach((item, index) => {
						const rows = item.closest(".rows").querySelector(".h2");
						const rowsH = document.querySelector(".rows:first-child").getBoundingClientRect().height;

						let tlmvRows = gsap.timeline({
							scrollTrigger: {
								id: `tlmvRows-${index}`,
								trigger: rows,
								start: `center center`,
								end: `+=${rowsH * 2}`,
								scrub: true, //end: `+=${h * 2}`,
								//immediateRender: true,
							},
						});
						tlmvRows.to(item, {
							scaleX: 0,
							ease: "none",
						});
					});

					const yValues = [-300, -127, -200];
					gsap.utils.toArray(".doubl_txt_wrap .dtw_img").forEach((item, index) => {
						//const yValue = parseFloat(item.getAttribute('data-img-value'));
						//console.log(item);
						let tldtwImg = gsap.timeline({
							scrollTrigger: {
								id: `tldtwImg-${index}`,
								trigger: item,
								start: `top bottom`,
								end: `bottom top`,
								scrub: true, //end: `+=${h * 2}`,
								//immediateRender: true,
							},
						});
						tldtwImg.to(item, {
							y: yValues[index],
							ease: "none",
						});
					});
				}

				if ($(".ln_v").length) {
					gsap.utils.toArray(".ln_v").forEach((item) => {
						const span = item.querySelector("span");
						let tlLNV = gsap.timeline({
							scrollTrigger: {
								id: "tlLNV",
								trigger: item,
								pin: false,
								pinSpacing: false,
								scrub: false,
								start: `top bottom-=50`,
								//end: `+=${h * 2}`,
								//immediateRender: true,
							},
						});
						tlLNV.to(span, 1, {
							scaleY: 1,
							ease: "none",
						});
					});
				}
				if ($(".ln_h").length) {
					gsap.utils.toArray(".ln_h").forEach((item) => {
						const span = item.querySelector("span");
						let tlLNH = gsap.timeline({
							scrollTrigger: {
								id: "tlLNH",
								trigger: item,
								pin: false,
								pinSpacing: false,
								scrub: false,
								start: `top bottom-=50`,
								//end: `+=${h * 2}`,
								//immediateRender: true,
							},
						});
						tlLNH.to(span, 1, {
							scaleX: 1,
							ease: "none",
						});
					});
				}
				if ($(".rq_cards_wrap_mb").length) {
					let rqCardsWrapMb = document.querySelector(".rq_cards_wrap_mb").getBoundingClientRect().height;

					let cardTl = document.querySelector(".rq_cards_wrap_mb .rq_card_mb");
					let cardHeight = cardTl.offsetHeight;

					//console.log(cardHeight);

					let cards = gsap.utils.toArray(".rq_card_mb");
					let cardsCounter = cards.length;
					let spacer = 20;
					let minScale = 0.7;
					let minOpacity = 0.7;
					let minOpacityP = 0;
					let distributor = gsap.utils.distribute({
						base: minScale,
						amount: 0.2,
					});
					let distributorOp = gsap.utils.distribute({
						base: minOpacity,
						amount: 0.0,
					});
					let distributorOpP = gsap.utils.distribute({
						base: minOpacityP,
						amount: 0.0,
					});

					let tlRCWMB = gsap.timeline({
						scrollTrigger: {
							id: "tlAPRMB",
							trigger: ".reliability_guarantee",
							pin: true,
							pinSpacing: false,
							scrub: true,
							start: `bottom bottom`,
							end: `+=${rqCardsWrapMb - cardHeight}`,
							//immediateRender: true,
						},
					});

					let tlRCWMBTTL = gsap.timeline({
						scrollTrigger: {
							id: "tlRCWMBTTL",
							trigger: "#rq_cards_wrap_mb_ttl",
							pin: false,
							pinSpacing: false,
							scrub: true,
							start: `top top`,
							//end: `+=${rqCardsWrapMb - cardHeight}`,
							immediateRender: true,
							//invalidateOnRefresh: true,
						},
					});
					tlRCWMBTTL.to(".reliability_guarantee .ttl", 2, {
						autoAlpha: 0,
						ease: "none",
					});

					cards.forEach((card, index) => {
						let scaleVal = distributor(index, card, cards);
						let opacityVal = distributorOp(index, card, cards);
						let opacityValForP = distributorOpP(index, card, cards);

						window.dispatchEvent(new Event("resize"));

						let container = card.parentElement;
						// if(index > 0){

						// }
						// let counterlast = index.length - 1;
						// console.log(counterlast);
						if (index < cardsCounter - 1) {
							let tween = gsap.to(card, {
								scrollTrigger: {
									trigger: ".rq_card_mb",
									start: `top top-=${index * cardHeight}`,
									end: `+=${cardHeight}`,
									scrub: true,
									// pinSpacing: true,
									invalidateOnRefresh: true,
								},
								ease: "none",
								// autoAlpha: opacityVal,
								scale: 0.85,
							});
						} else {
						}
					});

					// gsap.utils.toArray('.rq_card_mb').forEach((item, index) => {

					// 	let tlRCWMBSCL = gsap.timeline({
					// 		scrollTrigger: {
					// 			id: `tlAPRMBSCL_${index}`,
					// 			trigger: item,
					// 			pin: true,
					// 			pinSpacing: false,
					// 			scrub: true,
					// 			start: `top top`,
					// 			end: `+=${rqCardsWrapMb}`,
					// 			//immediateRender: true,
					// 		}
					// 	});
					// 	tlRCWMBSCL
					// 		.to(item, {scale: .88, y: 0, ease: 'power2.inOut'});

					// })
				}

				if ($(".wide_range_of_solutions").length) {
					const rArray = ["-3.488vw", "-3.488vw", "-3.488vw", "-24.419vw", "-24.419vw", "-24.419vw", "2.558vw", "2.558vw", "2.558vw"];
					gsap.utils.toArray(".wide_range_of_solutions .rows_mb .cols .r").forEach((item, index) => {
						const tlWROS = gsap.timeline({
							scrollTrigger: {
								id: "tlWROS",
								trigger: ".wide_range_of_solutions .rows_mb",
								pin: false,
								pinSpacing: false,
								scrub: true,
								start: `top bottom`,
								end: "bottom top+=200",
							},
						});
						tlWROS.to(item, {
							y: rArray[index],
							ease: "none",
						});
					});
				}
				if ($(".bg_move").length) {
					let tlBGM = gsap.timeline({
						scrollTrigger: {
							id: "tlBPX",
							trigger: ".bg_move",
							pin: false,
							pinSpacing: false,
							scrub: true,
							start: `top bottom`,
							end: "bottom top", //end: `+=${h * 2}`,
							//immediateRender: true,
						},
					});
					tlBGM.to(".bg_move img", 1, {
						y: "-10.938vw",
						ease: "none",
					});
				}

				if ($(".successful_work").length) {
					const tlLTR = gsap.timeline({
						scrollTrigger: {
							id: "tlLTR",
							trigger: ".successful_work .h2",
							start: "center bottom",
							//end: `bottom top`,
							//scrub: true
						},
					});
					tlLTR.to(".successful_work .h2_t", {
						y: 0,
						duration: 1.1,
						//ease: 'none',

						stagger: {
							amount: 0.35,
							ease: "power4.inOut",
						},
					});
				}
			},
			"(min-width: 761px) and (max-width: 6500px)": function () {
				const h = window.innerHeight;
				const w = window.innerWidth;

				if ($(".mn_screen").length) {
					let tlFixFirstScreen = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreen",
							trigger: ".mn_screen",
							pin: true,
							pinSpacing: true,
							scrub: 1,
							start: `top top`,
							end: `+=${h * 1.2}`,
							//immediateRender: true,
						},
					});

					let tlFixFirstScreenMove01 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreen",
							trigger: "#mns_id_set1",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove01
						.add("f")
						.to(
							".intro_img01",
							1,
							{
								xPercent: -250,
								yPercent: 220,
								ease: "none",
							},
							"f"
						)
						.to(
							".ms_txt",
							1,
							{
								autoAlpha: 0,
								ease: "none",
							},
							"f"
						)
						.to(
							".mdl_cnt",
							0.1,
							{
								autoAlpha: 1,
								ease: "none",
							},
							"f"
						);

					let tlFixFirstScreenMove01Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove01Ops",
							trigger: "#mns_id_set1_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove01Ops.to(".intro_img01", {
						autoAlpha: 0.3,
						ease: "none",
					});

					let tlFixFirstScreenMove02 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove02",
							trigger: "#mns_id_set2",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove02.to(".intro_img02", {
						xPercent: -180,
						yPercent: -250,
						ease: "none",
					});

					let tlFixFirstScreenMove02Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove02Ops",
							trigger: "#mns_id_set2_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove02Ops.to(".intro_img02", {
						autoAlpha: 0.3,
						ease: "none",
					});

					let tlFixFirstScreenMove06 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove06",
							trigger: "#mns_id_set6",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove06.to(".intro_img06", {
						xPercent: 195,
						yPercent: 255,
						ease: "none",
					});

					let tlFixFirstScreenMove06Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove06Ops",
							trigger: "#mns_id_set6_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove06Ops.to(".intro_img06", {
						autoAlpha: 0.3,
						ease: "none",
					});

					let tlFixFirstScreenMove05 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove05",
							trigger: "#mns_id_set5",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove05.to(".intro_img05", {
						xPercent: 165,
						yPercent: -150,
						ease: "none",
					});

					let tlFixFirstScreenMove05Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove05Ops",
							trigger: "#mns_id_set5_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove05Ops.to(".intro_img05", {
						autoAlpha: 0.3,
						ease: "none",
					});

					let tlFixFirstScreenMove04 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove04",
							trigger: "#mns_id_set4",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove04.to(".intro_img04", {
						xPercent: -250,
						yPercent: -320,
						ease: "none",
					});

					let tlFixFirstScreenMove04Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove04Ops",
							trigger: "#mns_id_set4_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove04Ops.to(".intro_img04", {
						autoAlpha: 0.3,
						ease: "none",
					});

					let tlFixFirstScreenMove07 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove07",
							trigger: "#mns_id_set7",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove07.to(".intro_img07", {
						xPercent: 235,
						yPercent: -360,
						ease: "none",
					});

					let tlFixFirstScreenMove07Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove07Ops",
							trigger: "#mns_id_set7_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove07Ops.to(".intro_img07", {
						autoAlpha: 0.3,
						ease: "none",
					});

					let tlFixFirstScreenMove03 = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove03",
							trigger: "#mns_id_set3",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=${h}`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove03.to(".intro_img03", {
						xPercent: -220,
						yPercent: -280,
						ease: "none",
					});

					let tlFixFirstScreenMove03Ops = gsap.timeline({
						scrollTrigger: {
							id: "tlFixFirstScreenMove03Ops",
							trigger: "#mns_id_set3_ops",
							pin: false,
							pinSpacing: false,
							scrub: 1,
							start: `top top`,
							end: `+=50`,
							//immediateRender: true,
						},
					});
					tlFixFirstScreenMove03Ops.to(".intro_img03", {
						autoAlpha: 0.3,
						ease: "none",
					});
					// let tlFixFirstScreenOpc = gsap.timeline({
					// 	scrollTrigger: {
					// 		id: "tlFixFirstScreenOpc",
					// 		trigger: '#mns_id_set2_ops',
					// 		pin: false,
					// 		pinSpacing: false,
					// 		scrub: 1,
					// 		start: `top top`,
					// 		end: `+=50`,
					// 		//immediateRender: true,
					// 	}
					// });
					// tlFixFirstScreenOpc
					// 	.add('fo')
					// 	.to('.intro_img01', {autoAlpha: .3,  ease: 'none'}, 'fo')
					// 	.to('.intro_img02', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img03', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img04', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img05', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img06', {autoAlpha: .3, ease: 'none'}, 'fo')
					// 	.to('.intro_img07', {autoAlpha: .3, ease: 'none'}, 'fo');
				}

			}
		});
	}
});
